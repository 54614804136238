<template>
  <form-field :name="field.name">
    <v-radio-group
      v-model="field.value"
      :rules="field.props.rules"
      required
    >
      <v-radio
        label="Bill via email"
        ripple
        value="email"
      />
      <v-radio
        label="Deliver printed bill"
        ripple
        value="address"
      />
    </v-radio-group>
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  mounted: function () {
    this.field.value = 'email'
  },
}
</script>
